import React from 'react';
import { Parallax } from "react-parallax";
import { Link } from 'react-router-dom'
import AOS from 'aos';
import whoAmI from '../assets/WhoamI1.jpg';

AOS.init();


const image1 ="./img/background/l11.jpg";

const Mblockquote = () => {
    return(
        <div  className="section bg-top bg-bottom py-0"
            >
          <Parallax className="pb-5" bgImage={image1} strength={300}>  
          <div className="py-5 position-relative"
            data-aos="fade"
            data-aos-delay="100"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
            >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h1 style={{textAlign: "center"}}>Who am I?</h1>
                            <div className="spacer-single"></div>
                            <blockquote className="q-big">
                            In my work, I am a generalist with versatile experience in business and in the creative realms of storytelling and concepting. My greatest passion burns for telling stories by creating scenes in explorable physical spaces that connect with visitors on deep levels. As a creative problem solver, I love being challenged by new territories and development opportunities. With this mindset, I got to work on world-renowned brands like Dyson, Wella or Durex and managed to create event-based immersive experiences and live performances as a freelancer in Hungary.                                
                             <div className="spacer-single"></div>
                            </blockquote>
                            <div className="spacer-single"></div>
                            
                            </div>
                            <div className="col-md-6"> <img className="img-fluid" height="700px"src={whoAmI}></img></div>
                            
                    </div>
                </div>
            </div>
        </Parallax>
        </div>
    );
}

export default Mblockquote;