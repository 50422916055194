import React from 'react';
import Masonry from "react-masonry-component";
import { createGlobalStyle } from 'styled-components';
import marriageMain from '../assets/marriageMain.jpg';
import brodyMain from '../assets/brodyMain.jpg';
import meacenasMain from '../assets/meacenasMain.PNG';
import borgyarMain from '../assets/borgyarMain.JPG';
import circusMain from '../assets/Circus1.jpg';
import csoport from '../assets/csoport.png';
import AOS from 'aos';
AOS.init();

const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;

const Gallery = function () {
    /* lightbox1 */
    const [lighbx, setlighbx] = React.useState(false);
    const handleBtnClick = (): void => {
        setlighbx(!lighbx);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "hidden";
    };
    const handleBtnClickclose = (): void => {
        setlighbx(!lighbx);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "auto";
    };

    /* lightbox2 */
    const [lighbx1, setlighbx1] = React.useState(false);
    const handleBtnClick1 = (): void => {
        setlighbx1(!lighbx1);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "hidden";
    };
    const handleBtnClickclose1 = (): void => {
        setlighbx1(!lighbx1);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "auto";
    };

    /* lightbox3 */
    const [lighbx2, setlighbx2] = React.useState(false);
    const handleBtnClick2 = (): void => {
        setlighbx2(!lighbx2);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "hidden";
    };
    const handleBtnClickclose2 = (): void => {
        setlighbx2(!lighbx2);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "auto";
    };

    const [lighbx3, setlighbx3] = React.useState(false);
    const handleBtnClick3 = (): void => {
        setlighbx3(!lighbx3);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "hidden";
    };
    const handleBtnClickclose3 = (): void => {
        setlighbx3(!lighbx3);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "auto";
    };

    const [lighbx4, setlighbx4] = React.useState(false);
    const handleBtnClick4 = (): void => {
        setlighbx4(!lighbx4);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "hidden";
    };
    const handleBtnClickclose4 = (): void => {
        setlighbx4(!lighbx4);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "auto";
    };
    return (
        <div className="container">
            <GlobalStyles />
            <div className="row">
                <div className="col-md-12">
                    <h4 style={{textAlign: "center"}}>Freelancer Creative Producing & Concepting under <i>Autumn Nights Productions</i></h4>
                    <div className="spacer-single"></div>
                    <img className="img-fluid" src={csoport} width="470px" style={{display:"block",marginLeft: "auto",marginRight: "auto"}}></img>
                    <div className="spacer-single"></div>
                    <h5 style={{textAlign: "center"}}>Story of Autumn Nights</h5>
                    <div className="spacer-single"></div>
                    <p style={{textAlign: "center"}}>The name Autumn Nights came to me on a crisp fall evening in 2010, on the closing
night of my first successful Halloween walk-through attraction in Pécs, Hungary. As
folktales say, the veil between our world and the world(s) beyond becomes rather
thin around Halloween. So thin that all dwellers of those realms may pass through
into ours. I have always found this concept rather exciting and Halloween is still the
most interesting holiday for me. Spending my teenage days in my hometown, I have
always longed for something more in terms of entertainment - apart from going to
the movies every other weekend and playing the drums in local bands. On that night,
I decided that I would pour this escapism into something constructive - I wanted to
keep that veil thin and bring the contents of other worlds to us. This is what Autumn
Nights stands for. A possibility to open up the gates and explore what’s beyond.</p>
                    <p style={{textAlign: "center"}}>Since 2010, I have designed and implemented several event-based immersive
experiences with live performance components in Hungary. I built a network of
creatives, artists, actors & dancers, technicians, constructors and clients.</p>
                    <div className="space-border"></div>
                </div>
            </div>
            <div className="spacer-single"></div>
            <h1 className="selectedP" style={{textAlign: "center"}}>Selected projects</h1>
            <div className="spacer-single"></div>
            <Masonry
                className={"row my-gallery-class"} elementType={"div"}>
                <div className="col-lg-4 image-element-class de_modal de_modal" onClick={handleBtnClick}
                    data-aos="fade-up"
                    data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>“Bőrgyár”: The leather factory</h3>
                            <h5 className="d-tag">Walk-through attraction</h5>
                        </div>
                        <img src={borgyarMain} alt="gallery" />
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick1}
                    data-aos="fade-up"
                    data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Marriage of the dead</h3>
                            <h5 className="d-tag">Event-based Immersive experience</h5>
                        </div>
                        <img src={marriageMain} alt="gallery" />
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick2}
                    data-aos="fade-up"
                    data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Brody Studios Halloween event “Dance of the Dead”</h3>
                            <h5 className="d-tag">Live Performance and theming</h5>
                        </div>
                        <img src={brodyMain} alt="gallery" />
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick3}
                    data-aos="fade-up"
                    data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Maecenas</h3>
                            <h5 className="d-tag">Immersive experience</h5>
                        </div>
                        <img src={meacenasMain} alt="gallery" />
                    </div>
                </div>
                <div className="col-lg-4 image-element-class de_modal" onClick={handleBtnClick4}
                    data-aos="fade-up"
                    data-aos-once="true"
                >
                    <div className="card-image-1">
                        <div className="d-text">
                            <h3>Circus of Horrors</h3>
                            <h5 className="d-tag">Event-based immersive experience</h5>
                        </div>
                        <img src={circusMain} alt="gallery" />
                    </div>
                </div>
            </Masonry>

            {/* lightbox1 */}
            {lighbx && (
                <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <div className="row g-4">
                                        <div className="col-lg-12 item">
                                            <img src="./img/single-gallery/Borgyar_design.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-4 item">
                                            <img src="./img/single-gallery/Borgyar2.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-8 item">
                                            <img src="./img/single-gallery/Borgyar3.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12 item">
                                            <img src="./img/single-gallery/Borgyar4.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-6 item">
                                            <img src="./img/single-gallery/Borgyar5.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-6 item">
                                            <img src="./img/single-gallery/Borgyar1.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-5 item">
                                            <img src="./img/single-gallery/Borgyar7.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-7 item">
                                            <img src="./img/single-gallery/Borgyar8.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 de_project-info">
                                <h3>“Bőrgyár”: The leather factory</h3>
                                <div className="spacer-single"></div>
                                <span>Walk-through attraction</span>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        
                                    </div>
                                    <div className="d-field">
                                    <i className="fa fa-calendar-o"></i>Date: <span>3-5.11.2011</span>
                                    </div>
                                    <div className="d-field"> 
                                        <i className="fa fa-file-text-o"></i>Venue: <span>Bőrgyár, Pécs, Hungary</span>
                                    </div>
                            
                                </div>
                                <div className="spacer-single"></div>
                                <div className="spacer-single"></div>
                                    <h5>Responsibilities:</h5>
                                    <div className="spacer-single"></div>
                                    <h3>Concept Development</h3> 
                                    <p>Developed a concept consisting of a walk-through attraction containing 18 rooms and an outdoor scarezone. </p>
                                    <div className="spacer-single"></div>
                                    <u>High concept:</u> 
                                    <p>A barely functioning, old leather factory gets overrun by a disgraced traveling circus. Bored of the weary workers already pushed into servitude, the society cannot wait for a fresh audience.</p>
                                    <div className="spacer-single"></div>
                                    <h3>Creative producing</h3>
                                    <p>Defined the project timeline and milestones for casting, rehearsals, construction, makeup, costume design and final dismantling. Communication of aforementioned milestones to the team of actors, creatives, constructors, technicians and other stakeholders. Live management of processes on site.</p>
                                    <div className="spacer-single"></div>
                                    <h3>Makeup</h3>
                                    <p>Created special effects makeup for twenty actors and performers.</p>
                                    <div className="spacer-30"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* lightbox2 */}
            {lighbx1 && (
                <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose1}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <div className="row g-4">
                                        <div className="col-lg-12 item">
                                            <img src="./img/single-gallery/marriage1.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 de_project-info">
                                <h3>Marriage of the dead</h3>
                                <div className="spacer-single"></div>
                                <span>Event-based Immersive experience</span>
                                <div className="de_project-details">
                                <div className="d-field">      
                                    </div>
                                    <div className="d-field"> 
                                        <i className="fa fa-file-text-o"></i>Event: <span>Bőrgyár, Pécs, Hungary</span>
                                    </div>
                                    <div className="d-field">
                                    <i className="fa fa-calendar-o"></i>Date: <span>31.10.2019</span>
                                    </div>
                                    <div className="d-field"> 
                                        <i className="fa fa-file-text-o"></i>Venue: <span>Trafik, Pécs, Hungary</span>
                                    </div>
                                </div>
                                <div className="spacer-single"></div>
                                <h5>Responsibilities:</h5>
                                    <div className="spacer-single"></div>
                                    <h3>Concept Development</h3> 
                                    <p>Developed a concept for a walk-through attraction leading into the venue.</p>
                                    <div className="spacer-single"></div>
                                    <u>High concept:</u> 
                                    <p>A journey through the vampires’ lair as invited guests of a noble wedding, the merger of two dynasties. What could go wrong?</p>
                                    <div className="spacer-single"></div>
                                    <h3>Creative producing</h3>
                                    <p>Defined the project timeline and milestones for rehearsals, construction, makeup and dismantling. Communication of aforementioned milestones to the team and the event owners & staff. Live management of processes on site. </p>
                                    <div className="spacer-single"></div>
                                    <h3>Makeup</h3>
                                    <p>Created special effects makeup for 9 actors.</p>
                                    <div className="spacer-30"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {lighbx2 && (
                <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose2}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <div className="row g-4">
                                        <div className="col-lg-12 item">
                                            <img src="./img/single-gallery/Brody1.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-6 item">
                                            <img src="./img/single-gallery/Brody2.png" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-6 item">
                                            <img src="./img/single-gallery/Brody5.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12 item">
                                            <img src="./img/single-gallery/Brody4.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-12 item">
                                            <img src="./img/single-gallery/Brody3.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 de_project-info">
                                <h3>Brody Studios Halloween event “Dance of the Dead”</h3>
                                <div className="spacer-single"></div>
                                <span>Live Performance and theming</span>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Date: <span>02.11.2019</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Venue: <span>Brody Studios, Budapest</span>
                                    </div>
                                    
                                </div>
                                <div className="spacer-single"></div>
                                <div className="spacer-single"></div>
                                    <h5>Responsibilities:</h5>
                                    <div className="spacer-single"></div>

                                    <p>Concept Development: Developed a concept for each of the three floors of the venue.</p>
                                    <div className="spacer-single"></div>
                                    
                                    <h5>Ground Floor: Samhain</h5>
                                    <div className="spacer-single"></div>
                                    <p>2,5m high Bonfire surrounded by a Gaelic ‘settlement’. Ritual dances of 3 live performers with a live music component featuring a handpan artist.</p>
                                    <div className="spacer-single"></div>
                                    <h5>2nd Floor – Day of the Dead</h5>
                                    <div className="spacer-single"></div>
                                    <p>Lady of the Dead character with a scythe, sitting on a large throne in the end of the main room looking down at the visitors and interacting with them. </p>
                                    <div className="spacer-single"></div>
                                    <h5>-1st Floor – Underground</h5>
                                   
                                    <div className="spacer-single"></div>
                                    <p>Hellish creature awaiting visitors in the catacombs.</p>
                                    <div className="spacer-single"></div>
                        
                                    <p>After midnight, the characters of the three realms broke loose and became more interactive with the guests and each other.</p>
                                    <h3>Creative producing</h3>
                                    <p>Effective communication of the concept to the client and delegating venue staff to synchronize efforts and go for the same vision. Defined the project timeline and milestones for casting, rehearsals, construction, makeup, costume design & sourcing and final dismantling. Drawing up aforementioned milestones to the team of actors, creatives, constructors and technicians. Live management of processes on site, looking after the team’s needs.</p>
                                    <div className="spacer-single"></div>
                                    <h3>Makeup</h3>
                                    <p>Created special effects makeup for one actor, the creature.</p>
                                    <div className="spacer-30"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {lighbx3 && (
                <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose3}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <div className="row g-4">
                                        <div className="col-lg-12 item">
                                            <img src="./img/single-gallery/meci3.JPG" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-6 item">
                                            <img src="./img/single-gallery/Maecenas2.PNG" alt="galleryimage" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-6 item">
                                            <img src="./img/single-gallery/Maecenas1.jpg" alt="galleryimage" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 de_project-info">
                                <h3>Maecenas Live</h3>
                                <div className="spacer-single"></div>
                                <span>Immersive experience</span>
                                <div className="de_project-details">
                                    <div className="d-field">
                                        
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Date: <span>13-14.09.2018</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Venue: <span>VNA Galéria, Pécs, Hungary</span>
                                    </div>
                                    
                                </div>
                                <div className="spacer-single"></div>
                                <div className="spacer-single"></div>
                                    <h5>Responsibilities:</h5>
                                    <div className="spacer-single"></div>
                                    <h3>Concept Development</h3> 
                                    <p>Developed a concept consisting of a walk-through attraction containing 12 different rooms. </p>
                                    <div className="spacer-single"></div>
                                    <u>High concept:</u>
                                    <p>Immersive display of an artist’s psychological states during a creative process, the birth of one particular painting, from the initial inspiration through self-doubt to creation.</p>
                                    <div className="spacer-single"></div>
                                    <h3>Creative producing</h3>
                                    <p>Defined the project timeline and milestones for casting, rehearsals, construction, makeup, costume design and final dismantling. Communication of aforementioned milestones to the team of actors, creatives, constructors, technicians and other stakeholders. Live management of processes on site and greeting visitors.</p>
                                    <div className="spacer-single"></div>
                                    <h3>Makeup</h3>
                                    <p>Created special effects makeup for three actors.</p>
                                    <div className="spacer-30"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

{lighbx4 && (
                <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose4}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <div className="row g-4">
                                        <div className="col-lg-12 item">
                                            <img src="./img/single-gallery/Circus1.jpg" alt="Circus" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-6 item">
                                            <img src="./img/single-gallery/Circus2.jpg" alt="Circus" className="img-fluid" />
                                        </div>
                                        <div className="col-lg-6 item">
                                            <img src="./img/single-gallery/Circus3.jpg" alt="Circus" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 de_project-info">
                                <h3>Circus of Horrors</h3>
                                <span>Event-based immersive experience</span>
                                <div className="spacer-single"></div>
                                <span>Pécs Music Society Halloween event</span>
                                <div className="de_project-details">
                                    <div className="spacer-single"></div>
                                   
                                    <div className="d-field">
                                        <i className="fa fa-calendar-o"></i>Date: <span>31.12.2018</span>
                                    </div>
                                    <div className="d-field">
                                        <i className="fa fa-file-text-o"></i>Venue: <span>Trafik, Pécs, Hungary</span>
                                    </div>
                                </div>
                                <div className="spacer-single"></div>
                                <div className="spacer-single"></div>
                                    <h5>Responsibilities:</h5>
                                    <div className="spacer-single"></div>
                                    <h3>Concept Development</h3> 
                                    <p>Developed a concept consisting of a walk-through attraction leading into the venue, a scarezone and live performers set in the theme pre-defined by the event. </p>
                                    <div className="spacer-single"></div>
                                    <u>High concept:</u> 
                                    <p>Visitors invited beyond the big tent where the artists live and practice become the main attraction in an out-of-this-world show about life and death. </p>
                                    <p>Featuring stilt walkers, jugglers, a fortune teller, clowns and various other creatures hidden within the circus troupe. </p>
                                    <div className="spacer-single"></div>
                                    <h3>Creative producing</h3>
                                    <p>Defined the project timeline and milestones for rehearsals, construction, makeup and dismantling. Communication of aforementioned milestones to the team and the event staff. Live management of processes on site.</p>
                                    <div className="spacer-single"></div>
                                    <h3>Makeup</h3>
                                    <p>Created special effects makeup for 10 actors and performers.</p>
                                    <div className="spacer-30"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Gallery;