import React from 'react';
import { Parallax } from "react-parallax";
import { Link } from 'react-router-dom'
import AOS from 'aos';
import highlights from '../assets/highlights.jpg';

AOS.init();


const image1 ="./img/background/l11.jpg";

const Mblockquote2 = () => {
    return(
        <div  className="section bg-top bg-bottom py-0"
            >
          <Parallax className="pb-5" bgImage={image1} strength={300}>  
          <div className="py-5 position-relative"
            data-aos="fade"
            data-aos-delay="100"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
            >
                <div className="container">
                    <div className="row align-items-center">
                    <div className="col-md-6"> <img className="img-fluid" height="750px"src={highlights}></img></div>
                        <div className="col-md-6">
                            <div className="spacer-single"></div>
                            <blockquote className="q-big">                            
                            <div className="spacer-single"></div>
                                <h5>A few highlights from the past:</h5>
                                <div className="spacer-single"></div>
                                <p>- As a freelancer, I orchestrated diverse teams creating immersive experiences for one of the most prestigious private event venues in Budapest, Brody Studios.  </p>
                                <p>- At Dyson, I successfully led the project of constructing the first Austrian Dyson live shopping studio along creative design guidelines and time pressure while also negotiating vital digital enhancements within the collaborations with the retailers.</p>
                                <p>- At SIROWA - a distributor company in the FMCG sector started in Hungary around 2017 - as a one-man army, I have built up a business unit through the negotiation of several complex commercial contracts containing a joint business plan, P&L and other long-term commercial terms, with the biggest players in the Hungarian market. The contracts are beneficial even to this day. </p>
                                <div className="spacer-single"></div>
                                My goal is to synergize my business skills, creative inclination and love for storytelling and create lasting memories in the themed entertainment industry.
                            </blockquote>
                            <div className="spacer-single"></div>
                            <Link to="/andras_szuts_cv.pdf" target="_blank" className="CvButton" download>Download CV</Link>
                            </div> 
                    </div>
                </div>
            </div>
        </Parallax>
        </div>
    );
}

export default Mblockquote2;