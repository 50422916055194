import React from 'react';
import AOS from 'aos';
import image from '../assets/AboutA.jpg';

AOS.init({
  offset: 200,
  duration: 800,
  easing: 'ease-in-out-sine',
  delay: 200,
  mirror: true
});

const hero = () => {
    return(
        <div className="v-center">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-md-6">
                        <img src={image} className="img-fluid" alt="Andras Szuts"/>
                    </div>
                    <div className="col-md-6">
                        <div className="spacer-20"></div>
                        <div className="h1_big">András Szüts</div>
                              <p className="homeP">Concepting | Project Management | Creative Producing | B2B Sales and Marketing | Event based immersive experiences | Live Performances |</p>
                        </div>
                    </div>

                   
                </div>
                
            </div>
            
       
    );
}

export default hero;